//React
import { useState, useEffect } from 'react';

//UI
import { Button } from "flowbite-react";
//import 'prismjs/themes/prism.css'; //Example style, you can use another
import "css/prism-vsc-dark.css";
import 'css/codeeditor.css';

//Services
import apiService from 'services/apiService';
import parseGradeCodeExerciseAILogic from 'logic/ai/parseGradeCodeExerciseAILogic';
import config from 'config';

//Logics
import codeEditorLogic from "logic/gridslate/codeEditor/codeEditorLogic";
import parseCodeEditor from "logic/parse/parseCodeEditor";

//Components
import Editor from 'react-simple-code-editor';

//Classes
import { CodeError } from 'classes/synapp/code_exercise/CodeError';
import { CodeExercise } from 'classes/synapp/code_exercise/CodeExercise';
import { CodeExerciseSubmission } from 'classes/synapp/code_exercise/CodeExerciseSubmission';
import { Status } from 'classes/enums/Status';
import { toast } from 'react-toastify';
//import { CodeExerciseSubmissionGrade } from 'classes/synapp/code_exercise/CodeExerciseSubmissionGrade';

type Props = {
    codeExercise: CodeExercise;
    codeExerciseSubmission: CodeExerciseSubmission;
    updateCodeExerciseSubmissionWithGrade: Function;
    editCodeExercise: Function;
    dueDatePassed: boolean;
}

const CodeExerciseSystemSingle = (props: Props) => {

    const { codeExercise, codeExerciseSubmission, updateCodeExerciseSubmissionWithGrade, editCodeExercise, dueDatePassed } = props;
    const [codeError, setCodeError] = useState<CodeError>(new CodeError());
    //const tabsRef = useRef<TabsRef>(null);
    const [isSubmittedForGrading, setIsSubmittedForGrading] = useState(false);
    //const [activeTab, setActiveTab] = useState(0);
    const [instructionCodeFlag, setInstructionCodeFlag] = useState(0); //0=Instructions, 1=Code, 2=Possible answer
    //const [possibleAnswerVisible, setPossibleAnswerVisible] = useState(false);

    useEffect(() => {
        setCodeError(new CodeError());
        //reset iframe content

        //Don't run on rerender
        //var iframe = document.getElementById('targetCode' + codeExercise.id) as HTMLIFrameElement;

        // if (!iframe) { return; }
        // if (iframe.contentDocument) {
        //     iframe.contentDocument.open();
        //     iframe.contentDocument.write(codeEditorLogic.getHead(codeExercise.id) + codeExerciseSubmission.sourceCode + codeEditorLogic.getTail());
        //     iframe.contentDocument.close();
        // }
    }, [codeExercise]);


    useEffect(() => {

        //console.log("Load reached");
        window.onmessage = function (e) {
            //console.log("Message:", e);

            if (typeof (e.data) == "string") {
                try {
                    let parsedError = JSON.parse(e.data);
                    //window.top.postMessage("error: "+evt.message +" at linenumber: "+evt.lineno+" of file: "+evt.filename, '*');
                    if (parsedError != null) {
                        setCodeError(parsedError);
                        //console.log(parsedError);
                    }
                }
                catch (err) {

                }
                //console.log(e.data);
            }

        };

        //TODO: Check if this is the right way to remove the event listener
        return () => {
            window.removeEventListener("message", () => { });
        }

    });



    const handleRunCodeClick = () => {
        if (codeError.lineNumber !== "") { setCodeError(new CodeError()); }
        let code = codeExerciseSubmission.sourceCode;
        if (instructionCodeFlag === 2) {
            code = codeExercise.possibleAnswer;
        }
        //let content = sourceCode;
        var iframe = document.getElementById('targetCode' + codeExercise.id) as HTMLIFrameElement;
        if (!iframe) { return; }

        if (iframe.contentDocument) {
            iframe.contentDocument.open();
            iframe.contentDocument.write(codeEditorLogic.getHead(codeExercise.id) + code + codeEditorLogic.getTail());
            iframe.contentDocument.close();
        }

        return false;
    }

    // const hightlightWithLineNumbers = (input, language) =>
    //   highlight(input, language)
    //     .split("\n")
    //     .map((line, i) => {
    //       if (errorLine === null || (errorLine !== null && i + 1 !== parseInt(errorLine))) {
    //         return `<span class='editorLineNumber'>${i + 1}</span>${line}`
    //       }
    //       if (errorLine !== null && i + 1 === parseInt(errorLine)) {
    //         return `<span class='editorLineNumber red-line'>${i + 1}</span>${line}`
    //       }
    //       return '';
    //     })
    //     .join("\n");

    const gradeByAI = async () => {
        //let request = { "Prompt": parseGradeCodeExerciseAILogic.returnPrompt(codeExercise, codeExerciseSubmission, "Korean") };
        let request = {
            "InstructionText": codeExercise.instructionText,
            "SourceCode": codeExerciseSubmission.sourceCode,
            "PossibleAnswer": codeExercise.possibleAnswer,
            "AdditionalResponseLanguage": "Korean"
        }
        //let response = await apiService.post(config.apiUrl + '/cms/testGPT', request);
        let response = await apiService.post(config.apiUrl + '/cms/AIGradeCodeExercise', request);
        if (response.success) {
            //console.log(response.payload);
            let grade = parseGradeCodeExerciseAILogic.parseNewAIResponse(response.payload);
            let newGrades = [...codeExerciseSubmission.grades];
            newGrades.push(grade);
            updateCodeExerciseSubmissionWithGrade({ ...codeExerciseSubmission, grades: newGrades });
            setIsSubmittedForGrading(false);

        } else {
            toast.error("Error grading code exercise!");
            setIsSubmittedForGrading(false);
        }

    }

    // const setOutputTab = () => {
    //     let activeTabIndex = dueDatePassed ? 3 : 2;
    //     tabsRef.current?.setActiveTab(activeTabIndex);
    // }

    const getGradingButtonText = () => {
        if (isSubmittedForGrading) {
            return "Grading...";
        } else if (codeExerciseSubmission.grades.length > 0) {
            return "Regrade";
        }
        return "Submit for grading";
    }

    return (
        <div className="w-full">
            <div className="w-full mt-4 min-h-80 grid grid-cols-1 md:grid-cols-2 bg-[#1F1F1F] rounded">
                <div className="border border-col-p2 border-4 m-2 p-2">
                    <Button className="float-right text-xs" onClick={() => { handleRunCodeClick() }} >Run Code</Button>
                    <div className="flex mb-2 md:mb-4">
                        <Button className="text-white cursor-pointer" onClick={() => setInstructionCodeFlag(0)}>Instructions</Button>
                        <Button className="text-white cursor-pointer ml-4" onClick={() => setInstructionCodeFlag(1)}>Code</Button>

                        {dueDatePassed && <Button className="text-white cursor-pointer ml-4" onClick={() => setInstructionCodeFlag(2)}>Possible Answer</Button>}
                    </div>

                    {instructionCodeFlag === 0 && <div className="whitespace-pre-wrap text-white min-h-80">
                        {codeExercise.instructionText}
                    </div>}
                    {instructionCodeFlag === 1 && <div className="min-h-80">
                        {codeError.lineNumber !== "" && <div className="text-red-500">Error on line {codeError.lineNumber}: {codeError.message}</div>}
                        <div className="code-editor-div">

                            <Editor
                                value={codeExerciseSubmission.sourceCode}
                                padding={10}
                                className="code-editor text-white"
                                onValueChange={(text: string) => editCodeExercise(text)}
                                highlight={(sourceCode) => parseCodeEditor.parseCodeLines(sourceCode, codeExerciseSubmission.codeExerciseId, codeError)}
                                textareaId="codeArea"
                            // style={{
                            //     fontFamily: '"Fira code", "Fira Mono", monospace',
                            //     fontSize: 18,
                            //     outline: 0,
                            // }}
                            />
                        </div>
                    </div>
                    }
                    {instructionCodeFlag === 2 && <div className="code-editor-div min-h-80">
                        <Editor
                            value={codeExercise.possibleAnswer}
                            padding={10}
                            className="code-editor text-white"
                            disabled
                            onValueChange={(sourceCode) => { }}
                            highlight={(sourceCode) => parseCodeEditor.parseCodeLines(sourceCode, codeExerciseSubmission.codeExerciseId, codeError)}
                            textareaId="codeArea"
                        />
                    </div>}


                </div>
                {/* //TODO: Add unique id here */}
                <div className="border border-col-p2 border-4 m-2 p-2">
                    <strong className="text-white">Output</strong>
                    {/* {codeExercise.id} */}
                    {codeExercise.id && <iframe
                        style={{
                            width: "100%",
                            height: "90%",
                        }}
                        name={"targetCode" + codeExercise.id}
                        title="Code Output"
                        id={"targetCode" + codeExercise.id}>
                    </iframe>}
                </div>

            </div>
            <div className="w-full mt-4 min-h-80 bg-[#1F1F1F] rounded text-white p-3">
                <strong>Grading</strong>

                {dueDatePassed && <div className="text-red-500">Due date passed. Grading not possible.</div>}
                {/* {!dueDatePassed && codeExerciseSubmission.status !== Status.unchanged && <div>Save before submitting for grading</div>} */}
                {!dueDatePassed && <div className="mb-4">
                    <Button
                        disabled={isSubmittedForGrading || dueDatePassed || codeExerciseSubmission.sourceCode === ""}
                        isProcessing={isSubmittedForGrading}
                        onClick={() => { setIsSubmittedForGrading(true); gradeByAI(); }}
                    >
                        {getGradingButtonText()}
                    </Button>

                </div>}
                {codeExerciseSubmission.grades.map((grade, index) => {
                    return (
                        <div key={index} className="grid gap-4">
                            <div>Final grade: {grade.grade}/10</div>
                            <div>Points: {grade.grade + " x difficulty (" + codeExercise.difficulty + ") = "} {grade.grade * codeExercise.difficulty} </div>
                            <div className="whitespace-pre-line">{grade.comments}</div>
                            <div>{grade.dateGraded}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default CodeExerciseSystemSingle;

