//import { Slide, Fade } from "react-awesome-reveal";
import { useState } from 'react';
import { Dropdown } from 'flowbite-react';
import { Container } from 'view_components/helper/HelperComponents';
import Dropzone from 'react-dropzone';
import docXService from 'services/docXService';
//import useS3Fetch from 'services/useS3Fetch';
//import "animate.css/animate.min.css";

const Test = () => {

    const handleImageToOCRUpload = async (acceptedFiles: any) => {
        let response = await docXService.handleImageForOCRUpload(acceptedFiles);
        console.log(response);
    }

    return (
        <Container>
            <Dropzone onDrop={(acceptedFiles) => {
                handleImageToOCRUpload(acceptedFiles);
            }}>
                {({ getRootProps, getInputProps }) => (
                    <section>
                        <div {...getRootProps()} className="flex w-full items-center justify-center h-32 cursor-pointer">
                            <input {...getInputProps()} />
                            <p>Drag 'n' drop some files here, or click to select files</p>
                        </div>
                    </section>
                )}
            </Dropzone>

        </Container >

    );
}

export default Test;