//Redirects from course browser
//Loads course and its master page

//React
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

//UI

//Services
import crudService from 'services/crudService';

import errorService from 'services/errorService';
import { useAtom } from 'jotai';
import { courseAtom } from 'atom';
import { courseProgressAtom } from 'atom';
import { breadCrumbsAtom } from 'atom';
import breadCrumbLogic from 'logic/utility/breadCrumbLogic';

//Logics

//Components

//Classes
import { FilterModel, PropertyFilter } from "classes/models/request/FilterModel";
import { Class } from 'classes/enums/Class';
import { Status } from 'classes/enums/Status';
import { Course } from 'classes/course/Course';

import { IsBusy } from 'classes/general/IsBusy';
import { CourseProgress } from 'classes/courseprogress/CourseProgress';
import { ChallengeProgress } from 'classes/courseprogress/ChallengeProgress';
import { PageCheckpointProgress } from 'classes/courseprogress/PageCheckpointProgress';
import RenderCourseContent from 'views/render/RenderCourseContent';
import { CourseChallenge } from 'classes/course/CourseChallenge';
import IndividualCourseProgress from 'views/coursemanagement/IndividualCourseProgress';
import { Button } from 'flowbite-react';

type Props = {
}

const ViewCourse = (props: Props) => {

    const { courseId } = useParams();
    const [nodeCourse, setCourse] = useAtom(courseAtom);
    const [courseProgress, setCourseProgress] = useAtom(courseProgressAtom);
    const [breadCrumbs, setBreadCrumbs] = useAtom(breadCrumbsAtom);
    const [isBusy, setIsBusy] = useState(new IsBusy());
    const [courseViewReady, setCourseViewReady] = useState(false);
    const [showCourseProgress, setShowCourseProgress] = useState(false);    

    useEffect(() => {

        const loadCourse = async () => {

            if (!courseId) {
                return;
            }

            setIsBusy(isBusy.loading(["nodeCourse"]));
            let filter = new FilterModel([new PropertyFilter("Id", courseId)]);
            crudService.load(Class.course, filter).then((response) => {
                if (response.success) {
                    let nodeCourse = response.payload as Course;
                    nodeCourse.status = Status.loaded;
                    setCourse(nodeCourse);
                    setBreadCrumbs(breadCrumbLogic.addBreadCrumb(nodeCourse.name, "/ViewCourse/" + nodeCourse.id, breadCrumbs));
                    setIsBusy(isBusy.loaded("nodeCourse"));
                } else {
                    errorService.handleError(response);
                    setIsBusy(isBusy.loaded("nodeCourse"));
                }
            });

        }

        const loadCourseProgress = async () => {
            if (!courseId) {
                return;
            }

            setIsBusy(isBusy.loading([Class.courseProgress]));
            let filter = new FilterModel([new PropertyFilter("CourseId", courseId)]);
            //This load will create the object if it does not exist
            let response = await crudService.load(Class.courseProgress, filter);
            if (response.success) {
                let thisCourseProgress = response.payload as CourseProgress;
                thisCourseProgress.status = Status.loaded;
                thisCourseProgress.visibilityUpdated = false;
                setCourseProgress(response.payload as CourseProgress);
            } else {
                errorService.handleError(response);
            }
            setIsBusy(isBusy.loaded(Class.courseProgress));
        }


        if (courseId && courseId !== "") {
            if (nodeCourse.status === Status.unloaded || nodeCourse.id !== courseId) {
                console.log("Loading  Course");
                loadCourse();
            } else {
                //loadPageMaster(nodeCourse.masterPageId);
                //loadMasterContentDocument(nodeCourse.masterContentDocumentId);
            }
            if (courseProgress.status === Status.unloaded || courseProgress.courseId !== courseId) {
                loadCourseProgress();
            }
        }

    }, [courseId]);


    //TODO: REDACT
    // const parseCheckpoints = (challenge: CourseChallenge, challengeProgress: ChallengeProgress) => {
    //     let newCheckpointProgresses = [...challengeProgress.checkpointProgresses];
    //     for (let checkpoint of challenge.checkpoints) {
    //         //If checkpoint does not exist in challenge, add it
    //         if (!challengeProgress.checkpointProgresses.find((x) => x.checkpointId === checkpoint.id)) {
    //             let newCheckpointProgress = new PageCheckpointProgress();
    //             newCheckpointProgress.checkpointId = checkpoint.id;
    //             newCheckpointProgress.elementId = checkpoint.elementId;
    //             newCheckpointProgress.type = checkpoint.type;
    //             newCheckpointProgresses.push(newCheckpointProgress);
    //         }
    //     }
    //     return newCheckpointProgresses;
    // }

    const parseCourseProgress = () => {

        let thisCourseProgress = { ...courseProgress };

        for (let challenge of nodeCourse.courseChallenges) {
            let challengeProgress = thisCourseProgress.challengeProgresses.find((x) => x.challengeId === challenge.id);
            //let challengeProgress = courseProgress.challengeProgresses.find((x) => x.challengeId === challenge.id);
            //If challenge does not exist in course progress, add it
            if (!challengeProgress) {
                challengeProgress = new ChallengeProgress();
                challengeProgress.challengeId = challenge.id;
                challengeProgress.courseContentId = challenge.courseContentId;
                challengeProgress.type = challenge.type;
                //challengeProgress.checkpointProgresses = parseCheckpoints(challenge, challengeProgress);
            } else {
                //If challenge exists in course progress, check if all checkpoints exist and add if not
                //challengeProgress.checkpointProgresses = parseCheckpoints(challenge, challengeProgress);
            }

            //Update/add challenge progress
            let index = thisCourseProgress.challengeProgresses.findIndex((x) => x.challengeId === challenge.id);
            if (index === -1) {
                thisCourseProgress.challengeProgresses.push(challengeProgress);
            } else {
                thisCourseProgress.challengeProgresses[index] = { ...challengeProgress };
            }
        }

        //Update course progress
        setCourseProgress(thisCourseProgress);
        console.log("Course Progress Updated: ", thisCourseProgress);
    }

    //TODO: This might need to be adjusted if changing courses in same session
    useEffect(() => {
        // console.log(" Course Status: ", nodeCourse.status);
        // console.log("Course Progress Status: ", courseProgress.status);
        if (nodeCourse.status !== Status.unloaded && courseProgress.status !== Status.unloaded) {
            console.log("Loaded  course: ", nodeCourse);
            parseCourseProgress();
            setCourseViewReady(true);
        }
    }, [nodeCourse.status, courseProgress.status]);

    //================================================================================== - Render Course

    return (
        <div className='container p-2 md:mx-auto'>
            {!courseViewReady && <div className="text-center mt-16">
                <img className='animate-spin m-auto w-[32] h-[32]' src={require('assets/images/synapp.png')}></img>
            </div>}
            {courseViewReady &&
                <div className="max-w-none prose prose-sm sm:prose-base lg:prose-base xl:prose-xl">
                    <Button onClick={() => setShowCourseProgress(!showCourseProgress)}>{showCourseProgress ? "Hide" : "Show"} Course Progress</Button>
                    {showCourseProgress && <IndividualCourseProgress courseId={courseId?courseId:""} />}
                    <RenderCourseContent contentJSON={JSON.parse(nodeCourse.masterContentDocument.contentDocument)} courseChallenge={null} renderCheckpoints={true} />
                </div>}

        </div>
    )

}

export default ViewCourse;