
import { Button, Table } from "flowbite-react";
import { useEffect, useState } from "react";
import { useAtom } from 'jotai';
import { userAtom } from 'atom';
import { HiInformationCircle } from "react-icons/hi";
import { Alert } from "flowbite-react";

type Props = {
    courseId: string;
}

const IndividualCourseProgress = (props: Props) => {
    const { courseId } = props;
    const [user] = useAtom(userAtom);

    const [studentNumberFound, setStudentNumberFound] = useState<boolean>(false);
    const [strippedCourseArray, setStrippedCourseArray] = useState<any[]>([]);
    const [sortBy, setSortBy] = useState<string>("TOTAL");
    const [showAlert, setShowAlert] = useState<boolean>(true);
    const [isMaster, setIsMaster] = useState<boolean>(false);
    //const [totalElligible, setTotalElligible] = useState<number>(0);

    let courseArray = [] as any[];
    let totalElligible = 0;
    if (courseId === "sHmtddrJkp") {
        courseArray = Course1Array;
        totalElligible = 25;
    } else {
        //console.log("Course Id: ", courseId);
        courseArray = Course2Array;
        totalElligible = 21;
    }

    let headers = ["ID", "Name", "Attendance", "AhaSlides", "PreSynapp and Checkpoints", "Code Exercises", "Total HW/Participation", "Midterm", "Final Exam", "TOTAL"];

    useEffect(() => {
        if (findStudentNumber(user.username)) {
            setStudentNumberFound(true);
        } else if (user.username === "master") {
            setStudentNumberFound(true);
            setIsMaster(true);
        } else {
            console.log(user);
            setStudentNumberFound(false);
            return;
        }

        //Remove all names and IDs from the array, except for the student's
        let strippedArray = courseArray.map((student, index) => {
            if (student[0] === user.username) {
                return student;
            } else {
                return ["", "", ...student.slice(2)];
            }
        });

        setStrippedCourseArray(setSortOrder(strippedArray, "TOTAL"));

    }, [courseId, user.username]);

    const findStudentNumber = (studentNumber: string) => {
        for (let i = 0; i < courseArray.length; i++) {
            if (courseArray[i][0] === studentNumber) {
                return courseArray[i];
            }
        }
        return null;
    }

    const setSortOrder = (arrayToSort: any[], headerString: any) => {
        let sortIndex = headers.indexOf(headerString);
        //Sort the array by the chosen header
        let sortedArray = arrayToSort.sort((a, b) => {
            if (a[sortIndex] > b[sortIndex]) {
                return -1;
            }
            if (a[sortIndex] < b[sortIndex]) {
                return 1;
            }
            return 0;
        });
        //setStrippedCourseArray(sortedArray);
        return sortedArray;

    }

    const getPredictedGrade = (index: number) => {
        let totalA = Math.floor(totalElligible * 0.3);
        let totalB = Math.floor(totalElligible * 0.4);

        if (index < totalA) {
            return "A";
        } else if (index < totalA + totalB) {
            return "B";
        } else {
            return "C || <";
        }
    }

    const showAllStudents = () => {
        setStrippedCourseArray(courseArray);
        setSortBy("TOTAL");
    }

    return (
        <>
            {!studentNumberFound && <div>Student not found</div>}
            {studentNumberFound &&
                <div className="overflow-x-auto">
                    {showAlert && <Alert color="failure" onDismiss={() => setShowAlert(false)} icon={HiInformationCircle}>
                        <span className="font-medium">This is an indication only.</span> There is NO guarantee that your final grade will reflect your current ranking.
                        <span className="font-medium">이는 적응증일 뿐입니다.</span> 최종 성적이 현재 순위를 반영한다는 보장은 없습니다.
                    </Alert>}
                    {/* <div>This is an indication only. There is NO guarantee that your final grade will reflect your current ranking. </div>
                    <div>이는 적응증일 뿐입니다. 최종 성적이 현재 순위를 반영한다는 보장은 없습니다.</div> */}
                    {isMaster && <div>
                        <div className="text-center">Master view</div>
                        <Button onClick={() => showAllStudents()}>Show all students</Button>
                    </div>
                    }


                    <Table>
                        <Table.Head>
                            <Table.HeadCell>Ranking</Table.HeadCell>
                            {headers.map((header, index) => {
                                return <Table.HeadCell
                                    className={header === sortBy ? "curso-pointer bg-col-p1 bold" : "cursor-pointer hover:bg-col-p1 "}
                                    key={"asdsd-" + index}
                                    onClick={() => {
                                        setSortBy("" + header)
                                        setStrippedCourseArray(setSortOrder(strippedCourseArray, header))
                                    }}
                                >{header}</Table.HeadCell>
                            })}
                            {sortBy === "TOTAL" && <Table.HeadCell>Predicted Grade</Table.HeadCell>}
                        </Table.Head>
                        <Table.Body className="divide-y">

                            {strippedCourseArray.map((student, index) => {
                                return (
                                    <Table.Row key={"asf-" + index}>
                                        <Table.Cell>{(index + 1)}</Table.Cell>
                                        {student.map((data: any, index2: number) => {
                                            return <Table.Cell className={(index2 === 9 && data < 30) ? "bg-red-400" : ""} key={"adwd-" + index2}>{data}</Table.Cell>
                                        })}
                                        {sortBy === "TOTAL" && <Table.Cell>{getPredictedGrade(index)}</Table.Cell>}
                                    </Table.Row>
                                )
                            })}

                        </Table.Body>
                    </Table>
                </div>

            }

        </>
    )

}

export default IndividualCourseProgress;

{/* <Table.HeadCell>ID</Table.HeadCell>
<Table.HeadCell>Name</Table.HeadCell>
<Table.HeadCell>Attendance</Table.HeadCell>
<Table.HeadCell>AhaSlides</Table.HeadCell>
<Table.HeadCell>PreSynapp and Checkpoints</Table.HeadCell>
<Table.HeadCell>Code Exercises</Table.HeadCell>
<Table.HeadCell>Total HW/Participation</Table.HeadCell>
<Table.HeadCell>Midterm</Table.HeadCell>
<Table.HeadCell>Final Exam</Table.HeadCell>
<Table.HeadCell>TOTAL</Table.HeadCell> */}

let Course1Array = [
    ['202010110', 'Hyun Park', 20, 7.5, 7.6, 3.5, 18.6, 14.87, 0, 53.47],
    ['202010239', '변인섭', 20, 7.6, 2.9, 5.2, 15.7, 15.39, 0, 51.09],
    ['202010254', 'sungil joo', 8.4, 4.9, 1, 3.2, 9.1, 12.7, 0, 30.2],
    ['202110684', '차동후', 15.5, 7.2, 4.2, 0, 11.4, 8.61, 0, 35.51],
    ['202110896', '김승찬', 20, 1.1, 1.2, 0, 2.3, 0, 0, 22.3],
    ['202210047', '김부경', 16.1, 3.2, 1.4, 0, 4.6, 5.74, 0, 26.44],
    ['202212048', 'Mohammad Jakaria Hossain Khan', 20, 8.7, 8.6, 8.2, 20, 14.96, 0, 54.96],
    ['202212050', 'Al Roman', 20, 7.7, 7.4, 8, 20, 15.04, 0, 55.04],
    ['202212066', 'Dylan Reed Fogler', 16.1, 6.1, 6.8, 1.6, 14.5, 9.3, 0, 39.9],
    ['202212136', 'Shokhrukh Shaydullo Ugli Yodgorov', 20, 8.1, 2.6, 5.1, 15.8, 13.91, 0, 49.71],
    ['202212141', 'Cain Joshua Ramos', 20, 9, 5.2, 8, 20, 17.91, 0, 57.91],
    ['202212171', 'Abubakar Shaik', 16.1, 8.9, 0.6, 3.5, 13, 10.61, 0, 39.71],
    ['202212206', 'Van Kip Lian Alfred', 20, 9.2, 5.3, 7.8, 20, 14.09, 0, 54.09],
    ['202301122', 'Blessing Onyemaechi Akoma', 20, 6.7, 7.7, 9.9, 20, 14.35, 0, 54.35],
    ['202310911', '신정아', 20, 5.6, 8.3, 8.6, 20, 10.61, 0, 50.61],
    ['202310919', '이은우', 8.4, 2.5, 7, 10, 19.5, 7.83, 0, 35.73],
    ['202310922', '이지예', 8.4, 4.8, 1.4, 1.6, 7.8, 1.83, 0, 18.03],
    ['202310925', '정선우', 16.1, 5.4, 8.1, 8.8, 20, 13.04, 0, 49.14],
    ['202312111', 'Enamul Bakee', 1.9, 2.5, 0.3, 0, 2.8, 6.61, 0, 11.31],
    ['202312116', 'Rakib Hossen', 2.6, 5.1, 0.2, 0.3, 5.6, 5.13, 0, 13.33],
    ['202312145', 'Md Ahosun Ullha Talukder', 20, 6.7, 3.3, 1.1, 11.1, 13.83, 0, 44.93],
    ['202312303', 'Islam Buvazov', 19.4, 9.4, 7.3, 2.4, 19.1, 14.87, 0, 53.37],
    ['202312308', 'Emma Leane Reveillon', 20, 9.6, 7.1, 2.5, 19.2, 18.35, 0, 57.55],
    ['202312338', 'Sanjar Isomiddin Ugli Tilavkobilov', 16.1, 7.5, 0.9, 2.5, 10.9, 9.74, 0, 36.74],
    ['202312342', 'Azmain Fayk Nilmoy', 20, 7.9, 6, 4.8, 18.7, 16.43, 0, 55.13],
    ['202312382', 'Rabi Lama', 0, , 0, 0, 0, 0, 0, 0],
];

let Course2Array = [
    ['202010249', '임정수', 20, 6.3, 7.1, 3.1, 16.5, 16.96, 0, 53.46],
    ['202010249', '임정수', 20, 6.3, 7.1, 3.1, 16.5, 16.96, 0, 53.46],
    ['202010253', 'Seonheum Cho', 20, 6.9, 5.7, 3.4, 16, 17.83, 0, 53.83],
    ['202112097', 'Gulnur Ismatullaeva', 20, 8.1, 4.4, 7.8, 20, 16.7, 0, 56.7],
    ['202112114', 'Mariia Mataru', 20, 8.1, 7.7, 7.7, 20, 17.57, 0, 57.57],
    ['202212060', 'Harishik Dev Singh Jamwal', 12.3, 4, 4.8, 9.1, 17.9, 14, 0, 44.2],
    ['202212148', 'Alo Nodir Kizi Nazarova', 20, 6.6, 7.5, 9, 20, 16.09, 0, 56.09],
    ['202212158', 'Zawad Al Sazid', 14.8, 6, 2, 2.4, 10.4, 15.57, 0, 40.77],
    ['202212190', 'Mst Sadia Akter Roksana', 12.3, 2.6, 0.9, 6.2, 9.7, 10.7, 0, 32.7],
    ['202212219', 'Mon Thant Kaung', 16.1, 6.6, 7.7, 5.1, 19.4, 15.83, 0, 51.33],
    ['202310603', 'HYUNJOON KIM', 19.4, 8.4, 4.8, 6.7, 19.9, 17.57, 0, 56.87],
    ['202312064', 'Nipuni Asamani Lewangamage', 16.1, 6.3, 4.5, 4.5, 15.3, 18.61, 0, 50.01],
    ['202312079', 'Jawad Bin Rashid', 19.4, 5.3, 2.6, 3.6, 11.5, 14.96, 0, 45.86],
    ['202312140', 'Md Fakrul Hasan Ucchas', 20, 8.1, 6.4, 4.2, 18.7, 17.04, 0, 55.74],
    ['202312168', 'Monijanur', 18.7, 4.3, 0.5, 3.2, 8, 0, 0, 26.7],
    ['202312232', 'Kirill Gurzhiy', 20, 8.8, 6.1, 7, 20, 18.78, 0, 58.78],
    ['202312239', 'Tatiana Zolotareva', 20, 7.1, 7.7, 5.4, 20, 16.43, 0, 56.43],
    ['202312294', 'Peiziev Ruslan', 8.4, 5, 1.8, 3.6, 10.4, 11.3, 0, 30.1],
    ['202312418', 'Vsevolod Skrepchuk', 20, 7.6, 5.3, 3.7, 16.6, 18.61, 0, 55.21],
    ['202312063', 'Firdavs Sirojiddin Ugli Salokhiddinov', 20, 0, 0, 0, 15, 15.3, 0, 50.3],
    ['202312083', 'Farrukhbek Nodirjon Ugli Nosirjonov', 16.1, 0, 0, 0, 10, 10.8, 0, 36.9],
    ['202312160', 'Amirov Shuhrat Komiljonovich', 20, 0, 0, 0, 10, 13.2, 0, 43.2],
]

//Original array
// let Course1Array = [

//     ["202010110", "Hyun Park", 20, 6.7, 5.7, 3.2, 15.6, 14.87, 0, 50.47],
//     ["202010239", "변인섭", 20, 7.3, 2.2, 1.4, 10.9, 15.39, 0, 46.29],
//     ["202010254", "sungil joo", 8.4, 3.8, 0.8, 2.8, 7.4, 12.7, 0, 28.5],
//     ["202110684", "차동후", 15.5, 6.4, 3.1, 0.1, 9.6, 8.61, 0, 33.71],
//     ["202110896", "김승찬", 20, 0.4, 0.9, 0, 1.3, 0, 0, 21.3],
//     ["202210047", "김부경", 16.1, 2.1, 1.1, 0, 3.2, 5.74, 0, 25.04],
//     ["202212048", "Mohammad Jakaria Hossain Khan", 20, 7.2, 6.4, 7, 20.6, 14.96, 0, 55.56],
//     ["202212050", "Al Roman", 20, 7, 5.5, 8.4, 20.9, 15.04, 0, 55.94],
//     ["202212066", "Dylan Reed Fogler", 16.1, 5.2, 5.1, 0.5, 10.8, 9.3, 0, 36.2],
//     ["202212136", "Shokhrukh Shaydullo Ugli Yodgorov", 20, 7.7, 1.9, 3.8, 13.4, 13.91, 0, 47.31],
//     ["202212141", "Cain Joshua Ramos", 20, 7.6, 3.9, 3.6, 15.1, 17.91, 0, 53.01],
//     ["202212171", "Abubakar Shaik", 16.1, 8.4, 0.5, 1.8, 10.7, 10.61, 0, 37.41],
//     ["202212206", "Van Kip Lian Alfred", 20, 8.5, 4, 8.2, 20.7, 14.09, 0, 54.79],
//     ["202301122", "Blessing Onyemaechi Akoma", 20, 5.9, 5.8, 6.8, 18.5, 14.35, 0, 52.85],
//     ["202310911", "신정아", 20, 5.1, 6.2, 8.6, 19.9, 10.61, 0, 50.51],
//     ["202310919", "이은우", 12.3, 2.2, 5.3, 10, 17.5, 7.83, 0, 37.63],
//     ["202310922", "이지예", 8.4, 4, 1.1, 1.6, 6.7, 1.83, 0, 16.93],
//     ["202310925", "정선우", 16.1, 4.8, 6.1, 8.8, 19.7, 13.04, 0, 48.84],
//     ["202312111", "Enamul Bakee", 4.5, 2, 0.2, 0, 2.2, 6.61, 0, 13.31],
//     ["202312116", "Rakib Hossen", 3.2, 4.4, 0.2, 0, 4.6, 5.13, 0, 12.93],
//     ["202312145", "Md Ahosun Ullha Talukder", 20, 6.3, 2.5, 0.7, 9.5, 13.83, 0, 43.33],
//     ["202312303", "Islam Buvazov", 19.4, 8.6, 5.5, 1.2, 15.3, 14.87, 0, 49.57],
//     ["202312308", "Emma Leane Reveillon", 20, 8.9, 5.3, 1.6, 15.8, 18.35, 0, 54.15],
//     ["202312338", "Sanjar Isomiddin Ugli Tilavkobilov", 16.1, 7.1, 0.7, 0.2, 8, 9.74, 0, 33.84],
//     ["202312342", "Azmain Fayk Nilmoy", 20, 8.8, 4.5, 3.7, 17, 16.43, 0, 53.43],
//     ["202312382", "Rabi Lama", 0, 0, 0, 0, 0, 0, 0, 0]
// ]

// let Course2Array = [
//     ["202010249", "임정수", 16.1, 6.7, 5.6, 3.3, 15.6, 16.96, 0, 48.66],
//     ["202010253", "Seonheum Cho", 16.1, 7.4, 4.6, 2.6, 14.6, 17.83, 0, 48.53],
//     ["202112097", "Gulnur Ismatullaeva", 20, 7.8, 3.6, 7.6, 19, 16.7, 0, 55.7],
//     ["202112114", "Mariia Mataru", 20, 8.1, 6.2, 6.3, 20.6, 17.57, 0, 58.17],
//     ["202212060", "Harishik Dev Singh Jamwal", 16.1, 4.6, 3.8, 10.1, 18.5, 14, 0, 48.6],
//     ["202212148", "Alo Nodir Kizi Nazarova", 20, 6.4, 6, 8.6, 21, 16.09, 0, 57.09],
//     ["202212158", "Zawad Al Sazid", 18.7, 7.2, 1.6, 3.7, 12.5, 15.57, 0, 46.77],
//     ["202212190", "Mst Sadia Akter Roksana", 12.3, 2.8, 0.7, 7.1, 10.6, 10.7, 0, 33.6],
//     ["202212219", "Mon Thant Kaung", 16.1, 6.9, 6.1, 7, 20, 15.83, 0, 51.93],
//     ["202310603", "HYUNJOON KIM", 15.5, 8.2, 3.9, 5.3, 17.4, 17.57, 0, 50.47],
//     ["202312064", "Nipuni Asamani Lewangamage", 16.1, 6, 3.6, 4.3, 13.9, 18.61, 0, 48.61],
//     ["202312079", "Jawad Bin Rashid", 19.4, 5.2, 2.1, 2.5, 9.8, 14.96, 0, 44.16],
//     ["202312140", "Md Fakrul Hasan Ucchas", 20, 8.4, 5.1, 2.6, 16.1, 17.04, 0, 53.14],
//     ["202312168", "Monijanur", 18.7, 4.7, 0.4, 2, 7.1, 0, 0, 25.8],
//     ["202312232", "Kirill Gurzhiy", 20, 8.7, 4.9, 5.6, 19.2, 18.78, 0, 57.98],
//     ["202312239", "Tatiana Zolotareva", 20, 7.1, 6.1, 5, 18.2, 16.43, 0, 54.63],
//     ["202312294", "Peiziev Ruslan", 8.4, 4.7, 1.4, 3.8, 9.9, 11.3, 0, 29.6],
//     ["202312418", "Vsevolod Skrepchuk", 20, 7.3, 4.2, 4, 15.5, 18.61, 0, 54.11],
//     ["202312063", "Firdavs Sirojiddin Ugli Salokhiddinov", 20, 0, 0, 0, 15, 15.3, 0, 50.3],
//     ["202312083", "Farrukhbek Nodirjon Ugli Nosirjonov", 16.1, 0, 0, 0, 10, 10.8, 0, 36.9],
//     ["202312160", "Amirov Shuhrat Komiljonovich", 20, 0, 0, 0, 10, 13.2, 0, 43.2]
// ]
