
import { parse } from "path";
import { useState } from "react";
import { Container } from 'view_components/helper/HelperComponents';

//Example of WEBVTT
// WEBVTT

// 1
// 00:00:08.550 --> 00:00:09.810 align:middle line:93%
// My name is Paul Romer.

// 2
// 00:00:09.880 --> 00:00:11.050 align:middle line:93%
// I'm an economist,

// 3
// 00:00:11.120 --> 00:00:12.050 align:middle line:93%
// and in these lectures,

// 4
// 00:00:12.120 --> 00:00:13.280 align:middle line:93%
// we'll be talking about

// 5
// 00:00:13.350 --> 00:00:15.320 align:middle line:93%
// the possibility of progress.

//This tool will extract the text from a WEBVTT file
const ExtractTextTool = () => {
    
    const [pastedtext, setPastedText] = useState<string>("");
    const [parsedText, setParsedText] = useState<string>("");

    const setAndParseText = (text: string) => {
        setPastedText(text);

        let currentText = "";
        //Parse the text
        let textArray = text.split("\n");
        //Remove all empty lines
        textArray = textArray.filter((line) => line.trim().length > 0);
        //Remove all lines that only contain numbers
        textArray = textArray.filter((line) => isNaN(parseInt(line.trim())));
        //Remove all lines that contain "WEBVTT"
        textArray = textArray.filter((line) => !line.includes("WEBVTT"));
        //Remove all lines that contain "-->"
        textArray = textArray.filter((line) => !line.includes("-->"));
        //Remove all lines that start with a timestamp
        textArray = textArray.filter((line) => !line.match(/^\d{2}:\d{2}:\d{2}.\d{3} --> \d{2}:\d{2}:\d{2}.\d{3}$/));
        //Remove all lines that contain "align:"
        textArray = textArray.filter((line) => !line.includes("align:"));
       
        
        setParsedText(textArray.join("\n"));
        //Copy to clipboard
        if (textArray.length > 0){
            navigator.clipboard.writeText(textArray.join(" "));
        }

        
    }

    return (
        <Container>
            <button onClick={() => {setAndParseText("")}}>Clear Text</button>            
            <div>
                <h1>WEBVTT Text</h1>
                <textarea onClick={() => {setAndParseText("")}} style={{width: "100%"}} value={pastedtext} rows={20} onChange={(e) => setAndParseText(e.target.value)}></textarea>
               
            </div>

            <div>
                {parsedText && <div>{parsedText}</div>}
            </div>

        </Container>

    );
}

export default ExtractTextTool;