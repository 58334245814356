//React
import { useEffect, useState } from 'react';

//UI
import { Button, Modal } from "flowbite-react";

//Services
import { useAtom } from 'jotai';
import { categoriesAtom } from "atom";
import { toast } from 'react-toastify';
import crudService from 'services/crudService';
import errorService from 'services/errorService';

//Logics
import createGUID from 'logic/utility/createGUID';

//Classes
import { Category } from 'classes/synapp/Category';
import { StatusCode } from 'classes/enums/StatusCode';
import { Class } from 'classes/enums/Class';
import { CheckpointTypes } from 'classes/enums/CheckpointTypes';
import { ChallengeCheckpoint } from 'classes/course/ChallengeCheckpoint';
import { ExtensionType } from 'classes/enums/ExtensionType';
import { CourseContent } from 'classes/course/CourseContent';   
//Components


type Props = {
    setShowAutoCreateModal: Function;
    courseContent: CourseContent;
    content: any;
    updateEditorContent: Function;
}

const AutoCreateCheckpointModal = (props: Props) => {

    const { setShowAutoCreateModal, courseContent, content, updateEditorContent } = props;

    const [stage, setStage] = useState<number>(1);
    const [potentialCategories, setPotentialCategories] = useState<string[]>([]);
    const [creatingCategoriesStatus, setCreatingCategoriesStatus] = useState<string>("none");
    const [newContentElements, setNewContentElements] = useState<any[]>([]);
    const [categories, setCategories] = useAtom(categoriesAtom);
   
    useEffect(() => {
        if (!content.content) {
            toast.error("No content found in editor");
            setShowAutoCreateModal(false);
            return;
        }

        let parentCategoryId = courseContent.categoryIds.length > 0 ? courseContent.categoryIds[courseContent.categoryIds.length - 1] : "None";
        if (parentCategoryId === "None") {
            toast.error("No parent category found");
            setShowAutoCreateModal(false);
            return;
        }

        let tempCategories = [] as string[];

        //Get all categories
        for (let element of content.content) {
            if (element.type === "heading" && element.content && element.content[0]?.text) {
                tempCategories.push(element.content[0].text);
            }
        }
        setPotentialCategories(tempCategories);

    }, []);

    const createCategories = async () => {
        let parentCategoryId = courseContent.categoryIds.length > 0 ? courseContent.categoryIds[courseContent.categoryIds.length - 1] : "None";

        let newCategories = [] as Category[];
        for (let category of potentialCategories) {
            let newCategory = new Category();
            newCategory.name = category.trim();
            newCategory.parentId = parentCategoryId;
            newCategory.level = courseContent.categoryIds.length; //Is this level correct?
            newCategory.id = createGUID(10);
            newCategory.statusCode = StatusCode.new;
            newCategories.push(newCategory);
        }

        //Check that categories with the same names don't already exist
        //let existingCategories = categories.filter((category) => potentialCategories.includes(category.name) && category.level === courseContent.categoryIds.length);
        let existingCategories = categories.filter((category) => potentialCategories.includes(category.name) && category.parentId === parentCategoryId);
        if (existingCategories.length > 0) {
            toast.error("Categories with the same names already exist");
            return;
        }

        setCreatingCategoriesStatus("creating");
        crudService.create(Class.category, newCategories).then(response => {
            if (response.success) {
                let newCategories = response.payload as Category[];
                let newCategoryIds = newCategories.map((category) => category.id);
                //update categories
                let oldCategories = [...categories];
                newCategories = oldCategories.concat(newCategories);
                setCategories(newCategories);
                setCreatingCategoriesStatus("complete");
            } else {
                errorService.handleError(response);
                setCreatingCategoriesStatus("error");
            }

        });
    }

    const findCategoryIds = (categoryName: string) => {
        let foundCategoryIds = [...courseContent.categoryIds];
        let category = categories.find((category) => category.name === categoryName);
        if (category) {
            foundCategoryIds.push(category.id);
            return foundCategoryIds;
        } else {
            console.warn("Category not found: ", categoryName);
            return foundCategoryIds;
        }
    }

    const createCheckpoints = () => {
        //let thisNewContent = { ...content };

        if (!content.content) {
            toast.error("No content found in editor");
            setShowAutoCreateModal(false);
            return;
        }

        //Iterate through content and create checkpoints before each new heading, except where:
        //1. Heading is the first element
        //2. Heading is the last element
        //3. Heading is followed by another heading
        let previousElement = null as any;
        let previousHeading = null as any;
        let elementCount = 0;
        let newContent = [] as any[];
        for (let element of content.content) {

            if (element.type === "heading" && elementCount !== 0 && previousElement.type !== "heading") {
                //console.log('Creating checkpoint before heading: ', previousHeading.content[0].text);
                let newElementId = createGUID(10);
                let newCheckpointObject = new ChallengeCheckpoint();
                newCheckpointObject.elementId = newElementId;
                newCheckpointObject.type = CheckpointTypes.Mcq;
                newCheckpointObject.categoryIds = findCategoryIds(previousHeading.content[0].text);
                newCheckpointObject.id = createGUID(10);

                let newCheckpointElement = {
                    type: ExtensionType.Checkpoint,
                    attrs: {
                        elementId: newElementId,
                        checkpoint: newCheckpointObject
                    }
                }
                newContent.push(newCheckpointElement);
            }
            elementCount++;
            previousElement = element;
            if (element.type === "heading") {
                previousHeading = element;
            }
            newContent.push(element);
        }

        //Add checkpoint after last element
        let newElementId = createGUID(10);
        let newCheckpointObject = new ChallengeCheckpoint();
        newCheckpointObject.elementId = newElementId;
        newCheckpointObject.type = CheckpointTypes.Mcq;
        newCheckpointObject.categoryIds = findCategoryIds(previousHeading.content[0].text);
        newCheckpointObject.id = createGUID(10);

        let newCheckpointElement = {
            type: ExtensionType.Checkpoint,
            attrs: {
                elementId: newElementId,
                checkpoint: newCheckpointObject
            }
        }
        newContent.push(newCheckpointElement);

        setNewContentElements(newContent);
    }

    // const updateEditorContent = () => {
    //   let newContent = { ...content };
    //   newContent.content = newContentElements;
    //   editor.commands.setContent(newContent);
    // }

    return (<Modal show={true} size="3xl" onClose={() => setShowAutoCreateModal(false)} popup>
        <Modal.Header>
            <div>Auto Create</div>
        </Modal.Header>
        <Modal.Body>
            {stage === 1 &&
                <div>
                    <div>Stage 1: Category Creation</div>
                    <div className="flex-auto">
                        <div>
                            {potentialCategories.map((category, index) => {
                                return <div key={"potential-categories-" + index} className="m-2 p-2 border border-2 border-slate-500">
                                    <span>{category}</span>
                                    {/* //Delete when X clicked */}
                                    <span className="m-2 p-2 border border-2 border-slate-500" onClick={() => {
                                        let newContent = [...potentialCategories];
                                        newContent.splice(index, 1);
                                        setPotentialCategories(newContent);
                                    }}>X</span>

                                </div>
                            })}
                        </div>
                    </div>

                    <Button disabled={creatingCategoriesStatus === "creating"} onClick={() => createCategories()}>Create categories</Button>
                    {/* <Button onClick={() => setCreatingCategoriesStatus("complete")}>Skip category creation</Button> */}
                    <Button disabled={creatingCategoriesStatus === "creating"} onClick={() => {
                        setStage(2);
                        createCheckpoints();
                    }
                    }>Next Stage</Button>
                </div>
            }
            {stage === 2 &&
                <div>
                    <div>Stage 2: Content Creation</div>
                    <div className="flex-auto">
                        <div>
                            {newContentElements.map((contentElement, index) => {
                                return <div key={"newcontent-elements-" + index} className="m-2 p-2 border border-2 border-slate-500">
                                    <span className={contentElement.type === "checkpoint" ? "bg-violet-400" : ""}>{contentElement.type}</span>
                                    {/* //Delete when X clicked */}
                                    <span className="m-2 p-2 border border-2 border-slate-500" onClick={() => {
                                        let newContent = [...newContentElements];
                                        newContent.splice(index, 1);
                                        setNewContentElements(newContent);
                                    }}>X</span>
                                </div>
                            })}
                        </div>
                    </div>

                    {/* <Button disabled={creatingCategoriesStatus === "creating"} onClick={() => createCategories()}>Create categories</Button> */}
                    {/* <Button onClick={() => setCreatingCategoriesStatus("complete")}>Skip checkpoint creation</Button> */}
                    <Button onClick={() => {
                        setStage(3);
                        let newContent = { ...content };
                        newContent.content = newContentElements;
                        updateEditorContent(newContent);
                        setShowAutoCreateModal(false);
                    }
                    }>Complete</Button>

                </div>
            }

        </Modal.Body>
        <Modal.Footer>
            <Button onClick={() => setShowAutoCreateModal(false)}>Close</Button>
        </Modal.Footer>

    </Modal>
    )
}

export default AutoCreateCheckpointModal;