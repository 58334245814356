//TODO: Currently used only for mcq checkpoints. If this is duel use for actual mcq challenges, then change name
//Otherwise, create seperate systems
//TODO: Counter update causing re-render of whole component. Need to fix this

//React
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';

//UI
import { Button } from "flowbite-react";

//Services
import crudService from 'services/crudService';
import { useAtom } from 'jotai';
import { windowDimensionsAtom } from 'atom';

//Logics
//import shuffleArray from "logic/utility/shuffleArray";
//import { useWindowDimensions } from "logic/hooks/useWindowDimensions";

//Components
import RenderPageSnippet from "views/render/RenderContentSnippet";
import Counter2 from "view_components/shared/Counter2";
import { toast } from 'react-toastify';

//Store
//import { useAtom } from 'jotai';
//import { loadedMcqs } from 'atom';

//Classes
//import { FilterModel } from "classes/models/request/FilterModel";
import { Class } from 'classes/enums/Class';
import { Mcq } from 'classes/synapp/mcq/Mcq';
import { McqSubmittedAnswer } from "classes/synapp/mcq/McqSubmittedAnswer";
import { McqRevisionChallenge } from 'classes/synapp/challenge/McqRevisionChallenge';

type Props = {
    mcqs: Mcq[];
    revisionChallenge: McqRevisionChallenge;
    updateChallenge: Function;
    parentId: string;
    parentType: string;
}

const McqChallengeSystem = (props: Props) => {

    //const { viewBreakpoint } = useWindowDimensions();
    const [windowDimensions] = useAtom(windowDimensionsAtom);
    const { mcqs, updateChallenge, revisionChallenge, parentId, parentType } = props;

    const navigate = useNavigate();
    const timerRef = useRef<any | null>(null);
    const [runTimer, setRunTimer] = useState(false);

    const [selectedMcqIndex, setSelectedMcqIndex] = useState(-1);
    const [challengeStatus, setChallengeStatus] = useState("waiting");
    //const [submittedAnswers, setSubmittedAnswers] = useState<McqSubmittedAnswer[]>([]);
    const [answerIds, setAnswerIds] = useState<string[]>([]);
    const [overViewCardSelected, setOverViewCardSelected] = useState(true);
    const [cheatActivated, setCheatActivated] = useState(false);
    //const [totalPointsAwarded, setTotalPointsAwarded] = useState(0);
    const [timeRemaining, setTimeRemaining] = useState(0);

    useEffect(() => {
        if (runTimer) {
            timerRef.current = (setInterval(() => {
                timerTick(revisionChallenge.startDate, revisionChallenge.duration);
            }, 1000));
        } else if (revisionChallenge.duration !== 0) {

            let timeDifference = Date.now() - revisionChallenge.startDate; // this is in thousand miliseconds
            let thisTimeRemaining = (revisionChallenge.duration) - (timeDifference / 1000);
            //If challenge deadline has passed:
            if (thisTimeRemaining <= 0 && revisionChallenge.challengeStatus === 1) {
                //toast.error("Challenge deadline has passed!");
                //console.log(thisTimeRemaining);
                gradeAnswers();
            } else {
                //console.log(thisTimeRemaining);
            }
        }

        return () => {
            clearInterval(timerRef.current);
        }; //cleanup function
    }, [runTimer]);

    useEffect(() => {
        setAnswerIds(revisionChallenge.answerIds);
        if (revisionChallenge.challengeStatus === 1) {
            setChallengeStatus("running");
            setRunTimer(true);
        } else if (revisionChallenge.challengeStatus === 2) {
            setChallengeStatus("completed");
        }
    }, [revisionChallenge]);

    const timerTick = (startTime: number, duration: number) => {
        //Check difference between start time and now
        let currentTime = new Date().getTime();
        let timeDifference = currentTime - startTime; // this is in thousand miliseconds
        let thisTimeRemaining = (duration) - (timeDifference / 1000);
        //Round up time remaining to nearest second
        //thisTimeRemaining = Math.round(thisTimeRemaining);
        thisTimeRemaining = Math.ceil(thisTimeRemaining);
        //Set new time remaining
        setTimeRemaining(thisTimeRemaining);
        //If time remaining is less than or equal to 0, stop timerRef
        if (thisTimeRemaining <= 0) {
            //gradeAnswers();
            stopTimer();
        }
    }

    const stopTimer = () => {
        setRunTimer(false);
        clearInterval(timerRef.current);
        timerRef.current = null;
    }

    const selectAnswer = (mcqId: string, answerLocalId: string) => {
        if (challengeStatus !== "running") {
            return;
        }

        let tempAnswerIds = [...answerIds];
        tempAnswerIds[selectedMcqIndex] = answerLocalId;
        setAnswerIds(tempAnswerIds);

    }

    const sendAnswersToServer = async (tempSubmittedAnswers: McqSubmittedAnswer[]) => {

        tempSubmittedAnswers.forEach(answer => {
            answer.parentId = parentId;
            answer.parentType = parentType;
            //Assuming local grading for now
            //answer.pointsAwarded = answer.isCorrect ? 1 : 0;
        });

        let response = await crudService.create(Class.mcqSubmittedAnswer, tempSubmittedAnswers);
        if (response.success) {
            console.log("Answers submitted successfully!", response.payload);
        }
        else {
            console.log("Error submitting answers!", response);
        }
    }

    const gradeAnswers = () => {
        console.log(JSON.stringify(answerIds));
        let numberOfCorrectAnswers = 0;
        let totalPointsAwarded = 0;
        let tempSubmittedAnswers = [] as McqSubmittedAnswer[];
        for (let i = 0; i < mcqs.length; i++) {

            //Create submitted answer
            tempSubmittedAnswers.push(new McqSubmittedAnswer());
            tempSubmittedAnswers[i].mcqId = mcqs[i].id;
            tempSubmittedAnswers[i].submittedAnswerId = answerIds[i];
            tempSubmittedAnswers[i].isGraded = true;

            let correctAnswer = mcqs[i].answers.find(x => x.isCorrect);
            if (!correctAnswer) {
                console.error("No correct answer found for mcq: " + mcqs[i].id);
                return;
            }
            if (answerIds[i] === correctAnswer.id) {
                tempSubmittedAnswers[i].isCorrect = true;
                tempSubmittedAnswers[i].pointsAwarded = 1 * mcqs[i].difficulty;
                totalPointsAwarded += tempSubmittedAnswers[i].pointsAwarded;
                numberOfCorrectAnswers++;
            } else {
                tempSubmittedAnswers[i].isCorrect = false;
            }
        }

        //Calculate total points awarded
        //setTotalPointsAwarded(totalPointsAwarded);

        //Send answers to server
        if (!cheatActivated) {
            //console.log("Trying to send answers to server");
            sendAnswersToServer(tempSubmittedAnswers);
        }

        //setSubmittedAnswers(tempSubmittedAnswers);

        completeChallenge(numberOfCorrectAnswers, totalPointsAwarded);
        //setChallengeStatus("completed");
    }

    const completeChallenge = (numberOfCorrectAnswers: number, totalPoints: number) => {
        let updatedRevisionChallenge = { ...revisionChallenge };
        updatedRevisionChallenge.answerIds = [...answerIds];
        updatedRevisionChallenge.numberOfCorrectAnswers = numberOfCorrectAnswers;
        updatedRevisionChallenge.pointsAwarded = totalPoints;
        updatedRevisionChallenge.dateCompleted = Date.now();
        updatedRevisionChallenge.challengeStatus = 2;
        updateChallenge(updatedRevisionChallenge, "submit");
    }


    //======================================================== Render and Render helper functions

    const renderChosenAnswerTick = (answerId: string) => {
        if (challengeStatus === "completed") {
            let userAnswerId = answerIds[selectedMcqIndex];
            let correctAnswer = mcqs[selectedMcqIndex].answers.find(x => x.isCorrect);
            if (userAnswerId === answerId) {
                if (userAnswerId === correctAnswer?.id) {
                    return "bg-green-500";
                } else {
                    return "bg-red-500";
                }
            } else {
                return "";
            }
        } else if (challengeStatus === "running") {
            if (answerIds[selectedMcqIndex] === answerId) {
                return "bg-yellow-500";
            } else {
                return "";
            }
        } else {
            return "";
        }

    }

    const allAnswersSubmitted = () => {
        if (challengeStatus !== "running") {
            return true;
        }
        console.log(answerIds)
        if (answerIds.includes("None")) {
            return false;
        } else {
            return true;
        }

    }

    const startChallenge = async () => {
        let success = await updateChallenge("start", []);
        if (!success) {
            console.error("Error starting challenge!");
            return;
        }
        setChallengeStatus("running");
        setSelectedMcqIndex(0);
    }

    const activateCheat = (e: any) => {
        if (e.detail === 3) {
            setCheatActivated(true);
            //Choose random answer for each mcq
            //let tempAnswerIds = [] as string[];
            let tempAnswerIds = [...answerIds]
           for (let i = 0; i < mcqs.length; i++) {
                let randomAnswer = mcqs[i].answers[Math.floor(Math.random() * mcqs[i].answers.length)];
                //tempAnswerIds.push(randomAnswer.id);
                tempAnswerIds[i] = randomAnswer.id;
            }
            setAnswerIds(tempAnswerIds);
        }
    }

    const saveProgress = (e: any) => {
        let updatedRevisionChallenge = { ...revisionChallenge };
        updatedRevisionChallenge.answerIds = answerIds;
        updateChallenge(updatedRevisionChallenge);
    }

    const renderOverviewCard = () => {

        if (challengeStatus === "waiting") {
            return (
                <div className="mx-auto mt-4 md:mt-6">
                    <div className="font-bold text-md md:text-xl mb-4 text-center">MCQ Revision Challenge</div>
                    <div className="flex gap-4 md:gap-6">
                        <div>
                            <div>Total Questions: {mcqs.length}</div>
                            <div>Duration: {revisionChallenge.duration}</div>
                        </div>
                        <Button onClick={() => { startChallenge(); }}>Start!</Button>
                    </div>
                </div>
            )
        }
        else if (challengeStatus === "completed") {

            return (
                <div>
                    <p>Challenge complete!</p>
                    <div>Total Questions: {mcqs.length}</div>
                    <div>Correct Answers: {revisionChallenge.numberOfCorrectAnswers}</div>
                    <div>Result: {Math.round((revisionChallenge.numberOfCorrectAnswers / mcqs.length) * 100)}% correct</div>
                    <div>Points Awarded: {revisionChallenge.pointsAwarded}</div>
                    <Button onClick={() => navigate(-1)}>Return to Course</Button>
                </div>
            )
        }
    }

    const getSelectionCardClassString = (index: number) => {
        let classString = "border-2 select-none text-xs p-3 m-3 w-20 h-16 ";
        classString += (selectedMcqIndex === index) ? " border-black" : " border-gray-300";
        if (challengeStatus === "running") {
            if (answerIds[index] === "None") {
                classString += " bg-gray-100";
            } else {
                classString += " bg-yellow-200";
            }
        }
        if (challengeStatus === "completed") {
            let correctAnswer = mcqs[index].answers.find(x => x.isCorrect);
            if (answerIds[index] === correctAnswer?.id) {
                classString += " bg-green-200";
            } else {
                classString += " bg-red-200";
            }
        }
        return classString;

    }

    const getMcqBoxWidth = () => {
        if (windowDimensions.breakpoint === "sm") {
            return "100%";
        } else if (windowDimensions.breakpoint === "md") {
            return "500px";
        } else if (windowDimensions.breakpoint === "lg") {
            return "600px";
        } else if (windowDimensions.breakpoint === "xl") {
            return "700px";
        } else if (windowDimensions.breakpoint === "2xl") {
            return "800px";
        }
    }

    //======================================================== Render

    // const reduceTimerTo10Seconds = () => {
    //     let updatedRevisionChallenge = { ...revisionChallenge };
    //     //Set startDate to 10 seconds before duration would end
    //     updatedRevisionChallenge.startDate = Date.now() - ((revisionChallenge.duration - 20) * 1000);
    //     updateChallenge(updatedRevisionChallenge);
    // }

    const isAnswersChanged = () => {
        let isChanged = false;
        for (let i = 0; i < mcqs.length; i++) {
            if (answerIds[i] !== revisionChallenge.answerIds[i]) {
                isChanged = true;
                break;
            }
        }
        return isChanged;
    }

    const renderAllAsHtml = () => {
        //Render all mcqs as html
        return (
            <div>
                {mcqs.map((mcq, index) => {
                    return (
                        <div key={mcq.id} className="p-4 mb-4 relative">
                            
                                {/* <div className="absolute left-0">{(index + 1) + ". "}</div> */}
                                <div><RenderPageSnippet contentJSON={JSON.parse(mcq.contentDocument)} /></div>
                            
                            <div>
                                {mcq.answers.map((answer, index) => {
                                    return (
                                        <div key={answer.id} className="flex">
                                            <div className="text-xs md:text-xl">{"abcde"[index] + ". " + answer.answerText}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <div className="grid grid-cols-1 place-items-center">
            <div className="font-bold text-lg md:2xl select-none text-center" onClick={(e: any) => activateCheat(e)}>MCQ Revision Challenge</div>
            {/* {cheatActivated && <Button onClick={() => setCheatActivated(false)}>Render All as Html</Button>} */}
            {/* {"Cheat activated is " + cheatActivated}
            {JSON.stringify(answerIds)} */}
            {/* <Button onClick={() => reduceTimerTo10Seconds()}>Reduce timerRef</Button> */}
            {challengeStatus === "running" && revisionChallenge.duration !== 0 && <div className="w-full mb-2 md:mb-4">
                <Counter2 duration={revisionChallenge.duration} timeRemaining={timeRemaining} timerDelay={1000} paused={false}></Counter2>
            </div>}

            <div className="flex gap-2 flex-wrap items-center">
                {challengeStatus === "completed" && <div className={"border-2 select-none text-xs p-3 m-3 w-20 h-16 " + (overViewCardSelected ? " bg-red-200" : " border-gray-300")}
                    onClick={() => { setSelectedMcqIndex(-1); setOverViewCardSelected(true) }}
                >Overview
                </div>}
                {challengeStatus !== "waiting" && mcqs && mcqs.map((mcq, index) => {
                    return (
                        <div key={"mcq-" + index} className={getSelectionCardClassString(index)}
                            onClick={() => { setSelectedMcqIndex(index); setOverViewCardSelected(false) }}
                        >
                            {"# " + (index + 1)}
                        </div>
                    )
                }
                )}
                {challengeStatus === "running" &&
                    <Button
                        disabled={!allAnswersSubmitted()}
                        className="h-12 mr-2 md:mr-4"
                        onClick={() => gradeAnswers()}
                    >Submit Answers</Button>}
                {challengeStatus === "running" &&
                    <Button
                        disabled={!isAnswersChanged()}
                        className="h-12"
                        onClick={(e: any) => saveProgress(e)}
                    >{isAnswersChanged() ? "Save Progress" : "Progress Saved"}</Button>}
            </div>

            {mcqs && mcqs[selectedMcqIndex] &&
                <div className="grid grid-cols-1 place-items-left" style={{ width: getMcqBoxWidth() }}>
                    <RenderPageSnippet contentJSON={JSON.parse(mcqs[selectedMcqIndex].contentDocument)} />
                    {mcqs.length > 0 && mcqs[selectedMcqIndex].answers.map((answer, index) => {
                        return (
                            <div key={answer.id} className="flex">
                                <div
                                    className={"cursor-pointer mr-4 mb-4 size-8 border-2 border-black " + renderChosenAnswerTick(answer.id)}
                                    onClick={() => selectAnswer(mcqs[selectedMcqIndex].id, answer.id)}
                                ></div>
                                <div className="text-xs md:text-xl">{answer.answerText}</div>
                            </div>
                        )
                    })}
                </div>
            }

            {overViewCardSelected &&
                renderOverviewCard()
            }
            {cheatActivated && renderAllAsHtml()}

        </div>
    );
}

export default McqChallengeSystem;

