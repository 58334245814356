//React
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//UI
//import { Button } from 'flowbite-react';
import { Modal, Select, Table } from "flowbite-react";
import Button from 'view_components/core/Button';
import MultiSelect from "react-tailwindcss-select";

//Services
import { useAtom } from 'jotai';
import { breadCrumbsAtom, categoriesAtom, windowDimensionsAtom } from 'atom';
import crudService from 'services/crudService';

//Logics
import breadCrumbLogic from 'logic/utility/breadCrumbLogic';
import parseMultiSelect from 'logic/parse/parseMultiSelect';
import timeLogic from 'logic/utility/timeLogic';

//Classes
import { McqRevisionChallenge } from 'classes/synapp/challenge/McqRevisionChallenge';
import { SelectOption } from 'classes/general/SelectOption';
import { FilterModel, PropertyFilter } from 'classes/models/request/FilterModel';
import { Class } from 'classes/enums/Class';
import errorService from 'services/errorService';
import { FetchStatus } from 'classes/enums/FetchStatus';
import createGUID from 'logic/utility/createGUID';

//Components

type Props = {
    courseId: string;
    elementId: string;
    selectedCategoryIds: string[];
    //courseContentName: string;
}

const McqRevisionChallengeElement = (props: Props) => {
    const { courseId, elementId, selectedCategoryIds } = props;

    //const SUCCESS = "success", ERROR = "error", IDLE = "idle", LOADING = "loading";
    //const { courseContentId, courseContentName } = props;
    const navigate = useNavigate();
    const [breadCrumbs, setBreadCrumbs] = useAtom(breadCrumbsAtom);
    const [categories, setCategories] = useAtom(categoriesAtom);
    //const [windowDimensions] = useAtom(windowDimensionsAtom);
    const [mcqRevisionChallenges, setMcqRevisionChallenges] = useState<McqRevisionChallenge[]>([]);
    //const [rcFetchStatus, setRcFetchStatus] = useState(new FetchTracker());
    const [rcFetchStatus, setRcFetchStatus] = useState<string>(FetchStatus.Idle);
    const [showCreateChallengeModal, setShowCreateChallengeModal] = useState(false);
    const [newRevisionChallenge, setNewRevisionChallenge] = useState<McqRevisionChallenge>(new McqRevisionChallenge(courseId, elementId, selectedCategoryIds));
    const [isCreating, setIsCreating] = useState(false);

    useEffect(() => {

        const getMcqRevisionChallenges = async () => {
            let filter = new FilterModel([new PropertyFilter("ElementId", elementId)]);
            filter.onlyOwner = true;
            let response = await crudService.get(Class.mcqRevisionChallenge, filter);
            if (response.success) {
                setMcqRevisionChallenges(response.payload);
                setRcFetchStatus(FetchStatus.Success);
            } else {
                errorService.handleError(response);
                setRcFetchStatus(FetchStatus.Error);
            }
        }

        getMcqRevisionChallenges();

    }, []);

    const CreateMcqRevisionModal = () => {

        useEffect(() => {

            const fetchCategories = async () => {
                let filter = new FilterModel();
                let response = await crudService.get(Class.category, filter);
                if (response.success) {
                    setCategories(response.payload);
                } else {
                    errorService.handleError(response);
                }
            }

            if (categories.length === 0) {
                fetchCategories();
            }

        }, []);

        const getOptionsFromCategories = () => {
            let options = [] as SelectOption[];

            for (let categoryId of selectedCategoryIds) {
                let category = categories.find(x => x.id === categoryId);
                if (category) {
                    options.push(new SelectOption(category.id, category.name));
                }
            }

            return options;
        }

        const getCategorySelectedValues = () => {

            let options = [] as SelectOption[];
            for (let i = 0; i < newRevisionChallenge.categoryIds.length; i++) {
                let category = categories.find(x => x.id === newRevisionChallenge.categoryIds[i]);
                if (category) {
                    options.push(new SelectOption(category.id, category.name));
                }
            }

            return options;
        }

        return (<Modal show={showCreateChallengeModal} size="xl" onClose={() => setShowCreateChallengeModal(false)} popup>
            <Modal.Header />
            <Modal.Body>
                {/* {JSON.stringify(selectedCategoryIds)} */}
                <div className="text-center">
                    Difficulty:
                    <Select value={newRevisionChallenge.difficulty} onChange={(e) => {
                        let challenge = { ...newRevisionChallenge };
                        challenge.difficulty = parseInt(e.target.value);
                        setNewRevisionChallenge(challenge);
                    }
                    }>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </Select>
                    Number of Questions:
                    <Select value={newRevisionChallenge.numberOfQuestions} onChange={(e) => {
                        let challenge = { ...newRevisionChallenge };
                        challenge.numberOfQuestions = parseInt(e.target.value);
                        setNewRevisionChallenge(challenge);
                    }
                    }>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                    </Select>
                    Challenge Duration:
                    <Select value={newRevisionChallenge.duration} onChange={(e) => {
                        let challenge = { ...newRevisionChallenge };
                        challenge.duration = parseInt(e.target.value);
                        setNewRevisionChallenge(challenge);
                    }
                    }>
                        <option value={30}>30 seconds</option>
                        <option value={5 * 60}>5 minutes</option>
                        <option value={10 * 60}>10 minutes</option>
                        <option value={20 * 60}>20 minutes</option>
                        <option value={40 * 60}>40 minutes</option>
                        <option value={60 * 60}>1 hour</option>
                        <option value={0}>No limit</option>
                    </Select>

                    Categories:

                    <MultiSelect
                        value={getCategorySelectedValues()}
                        isMultiple={true}
                        onChange={(e) => { setNewRevisionChallenge({ ...newRevisionChallenge, categoryIds: parseMultiSelect.multiSelectOptionsToStringArray(e) }) }}
                        options={getOptionsFromCategories()}
                        primaryColor='#4F46E5'
                    />

                    <div className="flex justify-center gap-4">
                        <Button onClick={() => { setShowCreateChallengeModal(false); setIsCreating(true); createNewRevisionChallenge(); }}>
                            {"Create"}
                        </Button>
                        <Button color="gray" onClick={() => setShowCreateChallengeModal(false)}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>)
    }

    const createNewRevisionChallenge = async () => {
        //Regenerate id
        newRevisionChallenge.id = createGUID(10);
        //console.log(newRevisionChallenge);
        setIsCreating(true);
        let response = await crudService.create(Class.mcqRevisionChallenge, newRevisionChallenge);
        if (response.success) {
            let challenge = response.payload as McqRevisionChallenge;
            //console.log("Created challenge: ", challenge);
            setMcqRevisionChallenges([...mcqRevisionChallenges, challenge]);
            setIsCreating(false);
        } else {
            errorService.handleError(response);
            setIsCreating(false);
        }
    }

    const returnTimeRemaining = (challenge: McqRevisionChallenge) => {
        if (challenge.challengeStatus === 0) {
            return "Not started";
        } else if (challenge.challengeStatus === 2) {
            return "Completed";
        } else {
            return timeLogic.returnTimeRemainingString(challenge.startDate, challenge.duration);
        }
    }

    const returnChallengeStatusString = (status: number) => {
        switch (status) {
            case 0:
                return "Not started";
            case 1:
                return "In progress";
            case 2:
                return "Completed";
            default:
                return "Unknown";
        }
    }

    const getButtonText = (status: number) => {
        if (status === 0) return "Start";
        if (status === 1) return "Resume";
        if (status === 2) return "Review";
        // if (windowDimensions.width > 640) {
        //     if (status === 0) return "Start Challenge";
        //     if (status === 1) return "Resume Challenge";
        //     if (status === 2) return "Review Challenge";
        // } else {
        //     if (status === 0) return "Start";
        //     if (status === 1) return "Resume";
        //     if (status === 2) return "Review";
        // }

        return "View Challenge";
    }

    const returnChallengeTableRow = (challenge: McqRevisionChallenge) => {
        return (
            <div key={challenge.id} className="grid grid-cols-6 text-xs md:text-base items-center justify-items-center mb-1 md:mb-2">
                <div>{challenge.difficulty}</div>
                <div>{challenge.numberOfQuestions}</div>
                <div>{timeLogic.returnDurationString(challenge.duration)}</div>
                <div>{returnTimeRemaining(challenge)}</div>
                {challenge.challengeStatus === 2 &&
                    <div>{Math.round((challenge.numberOfCorrectAnswers / challenge.questionIds.length) * 100) + "%"}</div>
                }
                 {challenge.challengeStatus !== 2 &&
                    <div>{"N/A"}</div>
                }
                
                {/* <div>{returnChallengeStatusString(challenge.challengeStatus)}</div>
                <div>{challenge.categoryIds}</div> */}
                {/* <div><Button onClick={() => {
                    //To CourseContentViewer
                    setBreadCrumbs(breadCrumbLogic.addBreadCrumb("MCQ Revision Challenge", "/McqRevisionChallengeWrapper/" + challenge.id, breadCrumbs));
                    navigate("/McqRevisionChallengeWrapper/" + challenge.id);
                }}>{getButtonText(challenge.challengeStatus)}</Button></div> */}
                <div
                    className="inline-block text-center p-1 md:p-2 text-xs md:text-base border border-2 cursor-pointer border-col-s2 bg-col-t1 hover:brightness-90 font-bold text-col-p2 rounded"
                    onClick={() => {
                        setBreadCrumbs(breadCrumbLogic.addBreadCrumb("MCQ Revision Challenge", "/McqRevisionChallengeWrapper/" + challenge.id, breadCrumbs));
                        navigate("/McqRevisionChallengeWrapper/" + challenge.id);
                    }}>{getButtonText(challenge.challengeStatus)}</div>
            </div>
        )
    }

    return (
        <div>
            {/* hover:bg-col-t1 */}
            <div className="border border-col-p2 border-2  m-2 flex flex-col items-center justify-center relative">
                <div className="bg-col-p2 text-white rounded-br-md font-bold text-xs md:text-sm p-1 md:p-2 absolute top-0 left-0">MCQ Revision Challenge</div>
                {/* <div className="text-lg md:text-2xl font-black mx-2 md:mx-4 mt-6 md:my-2">{courseContentName}</div> */}
                <div className="content">
                    {rcFetchStatus === FetchStatus.Loading && <div>Loading...</div>}
                    {rcFetchStatus === FetchStatus.Error && <div>Error Loading Challenges</div>}

                </div>
                <div className="content">
                    {rcFetchStatus === FetchStatus.Success && <div className="w-full mt-8 mr-2 mr-4">
                        <div className="font-bold text-xs md:text-sm grid grid-cols-6 items-center justify-items-center mb-2 md:mb-4">
                            <div>Difficulty</div>
                            <div>Questions</div>
                            <div>Duration</div>
                            <div>Time Left</div>
                            <div>Result</div>
                            <div>Actions</div>
                        </div>

                        {mcqRevisionChallenges.map((challenge, index) => {
                            return (
                                returnChallengeTableRow(challenge)
                            )
                        })}

                    </div>}
                </div>
                {rcFetchStatus === FetchStatus.Success &&
                    <Button
                        className="mb-4"
                        onClick={() => { setShowCreateChallengeModal(true) }}
                    >{!isCreating ? "Create New Revision Challenge" : "Creating..."}</Button>}

            </div>

            {showCreateChallengeModal && <CreateMcqRevisionModal />}

        </div>
    )

}

export default McqRevisionChallengeElement;


