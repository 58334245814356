//React
import React from 'react';
import { useState, useEffect } from 'react';

//UI
import { Select, Button } from "flowbite-react";

//Services
import { useAtom } from 'jotai';
import { courseAtom, totalCourseProgressAtom } from "atom";
import config from 'config';
import apiService from 'services/apiService';
import errorService from 'services/errorService';
import crudService from 'services/crudService';

//Logics


//Classes
//import GetTotalProgressResponseModel from 'classes/models/response/GetTotalProgressResponseModel';
import { CourseProgress } from 'classes/courseprogress/CourseProgress';
import { CourseEnrollment } from 'classes/course/CourseEnrollment';
import { FilterModel, PropertyFilter } from 'classes/models/request/FilterModel';
import { Class } from 'classes/enums/Class';
import { CodeExerciseSubmission } from 'classes/synapp/code_exercise/CodeExerciseSubmission';
import { CourseChallenge } from 'classes/course/CourseChallenge';
import { CourseChallengeType } from 'classes/enums/CourseChallengeType';
import { ChallengeCheckpoint } from 'classes/course/ChallengeCheckpoint';
import groupBy from 'logic/utility/groupBy';
import createGUID from 'logic/utility/createGUID';
import parseGradeCodeExerciseAILogic from 'logic/ai/parseGradeCodeExerciseAILogic';
import { CodeExercise } from 'classes/synapp/code_exercise/CodeExercise';

//Components


type Props = {
    courseId: string;
    courseEnrollments: CourseEnrollment[];
};


const ViewCourseProgress = (props: Props) => {

    const { courseId, courseEnrollments } = props;

    const [course] = useAtom(courseAtom);
    const [loadedChallengeIds, setLoadedChallengeIds] = useState<string[]>([]);
    const [selectedChallengeId, setSelectedChallengeId] = useState<string>("none");
    const [courseProgresses, setCourseProgresses] = useState<CourseProgress[]>([]);
    //const [courseEnrollments, setCourseEnrollments] = useState<CourseEnrollment[]>([]);
    const [codeExerciseSubmissions, setCodeExerciseSubmissions] = useState<CodeExerciseSubmission[]>([]);
    const [allCodeExerciseSubmissions, setAllCodeExerciseSubmissions] = useState<CodeExerciseSubmission[]>([]);
    const [showPointTotalType, setShowPointTotalType] = useState<string>("none");

    // useEffect(() => {
    //     const getCourseEnrollments = async () => {
    //         let filter = new FilterModel([new PropertyFilter("CourseId", course.id)]);
    //         await crudService.get(Class.courseEnrollment, filter).then((response) => {
    //             if (response.success) {
    //                 setCourseEnrollments(response.payload as CourseEnrollment[]);
    //             } else {
    //                 errorService.handleError(response);
    //             }
    //         });
    //     }

    //     if (courseEnrollments.length === 0) {
    //         getCourseEnrollments();
    //     }
    // });

    useEffect(() => {
        console.log(course);
    }, [course]);

    useEffect(() => {
        const getCourseProgresses = async () => {
            let filter = new FilterModel([new PropertyFilter("CourseId", course.id)]);
            await crudService.get(Class.courseProgress, filter).then((response) => {
                if (response.success) {
                    setCourseProgresses(response.payload as CourseProgress[]);
                } else {
                    errorService.handleError(response);
                }
            });
        }

        const getAllCodeExerciseSubmissions = async () => {
            //let filter = new FilterModel([new PropertyFilter("CourseId", course.id)]);
            let filter = new FilterModel([]);
            await crudService.get(Class.codeExerciseSubmission, filter).then((response) => {
                if (response.success) {
                    console.log("All CodeExerciseSubmissions: ", response.payload);
                    setAllCodeExerciseSubmissions(response.payload as CodeExerciseSubmission[]);
                } else {
                    errorService.handleError(response);
                }
            });
        }

        if (courseProgresses.length === 0) {
            getCourseProgresses();
            getAllCodeExerciseSubmissions();
        }
    });

    const getCodeExerciseSubmissions = async (challengeId: string) => {
        let filter = new FilterModel([new PropertyFilter("ChallengeId", challengeId)]);
        await crudService.get(Class.codeExerciseSubmission, filter).then((response) => {
            if (response.success) {
                let fetchedCodeExerciseSubmissions = response.payload as CodeExerciseSubmission[];
                setCodeExerciseSubmissions([...codeExerciseSubmissions, ...fetchedCodeExerciseSubmissions]);
                setLoadedChallengeIds([...loadedChallengeIds, challengeId]);
            } else {
                errorService.handleError(response);
            }
        });
    }

    // useEffect(() => {

    //     const getTotalProgress = async () => {

    //         await apiService.get(config.apiUrl + "/cms/getTotalProgress/" + course.id).then((response) => {
    //             if (response.success) {
    //                 let totalCourseProgress = response.payload as GetTotalProgressResponseModel;
    //                 setTotalCourseProgress(totalCourseProgress.courseProgresses);
    //                 setCourseEnrollments(totalCourseProgress.courseEnrollments);

    //             } else {
    //                 errorService.handleError(response);
    //             }
    //         });

    //     }

    //     if (totalCourseProgress.length === 0) {
    //         getTotalProgress();
    //     }


    // }, [totalCourseProgressAtom]);

    const getTotalCheckpointPoints = (userId: string, challengeId: string, studentNumber: string) => {
        // if (studentNumber === "202212048") {
        //     let thisCourseProgress = courseProgresses.find((x) => x.userId === userId);
        //     let thisChallengeProgress = thisCourseProgress?.challengeProgresses.find((x) => x.challengeId === challengeId);
        //     console.log(thisChallengeProgress);
        // }
        let totalPoints = 0;
        courseProgresses.forEach((courseProgress: CourseProgress) => {
            if (courseProgress.userId === userId) {
                let challengeProgress = courseProgress.challengeProgresses.find((x) => x.challengeId === challengeId);
                if (challengeProgress) {
                    //Add 1 point for each completed checkpoint

                    for (let checkpointProgress of challengeProgress.checkpointProgresses) {
                        if (checkpointProgress.pointsAwarded > 0) {
                            totalPoints += checkpointProgress.pointsAwarded;
                        } else {
                            totalPoints += 1;
                        }
                    }

                    totalPoints = challengeProgress.checkpointProgresses.filter((x) => x.pointsAwarded > 0).length;
                    // if (challengeProgress.pointsAwarded) {
                    //     totalPoints = challengeProgress.pointsAwarded;
                    // } else {
                    //     //Add 1 point for each completed checkpoint
                    //     totalPoints = challengeProgress.checkpointProgresses.filter((x) => x.pointsAwarded > 0).length;
                    //     console.log("undefined found for challengeProgress.pointsAwarded");
                    // }
                    //totalPoints = challengeProgress.checkpointProgresses.reduce((acc: number, checkpointProgress) => acc + checkpointProgress.pointsAwarded, 0);
                }
            }
        });
        return totalPoints;

    }


    const renderCheckpointsProgressPerStudent = (challenge: CourseChallenge) => {

        return (
            <>
                {courseEnrollments.map((enrollment: CourseEnrollment, index: number) => {
                    return (
                        <div key={enrollment.id} className="grid grid-flow-col-dense hover:bg-col-s2">
                            <div>{enrollment.studentNumber}</div>
                            {challenge.checkpoints.map((checkpoint: ChallengeCheckpoint) => {
                                return (
                                    <div key={checkpoint.id}>
                                        <div>
                                            {courseProgresses.map((courseProgress: CourseProgress) => {
                                                if (courseProgress.userId === enrollment.userId) {
                                                    let challengeProgress = courseProgress.challengeProgresses.find((x) => x.challengeId === challenge.id);
                                                    if (challengeProgress) {
                                                        let checkpointProgress = challengeProgress.checkpointProgresses.find((x) => x.elementId === checkpoint.elementId);
                                                        if (checkpointProgress) {
                                                            return (
                                                                <div className="text-center" key={checkpointProgress.id}>
                                                                    <span>✔️</span>
                                                                    {/* <div>{checkpointProgress.pointsAwarded}</div> */}
                                                                </div>
                                                            )
                                                        } else {
                                                            return (
                                                                <div className="text-center" key={checkpoint.id}>
                                                                    <span>❌</span>
                                                                </div>
                                                            )
                                                        }
                                                    } else {
                                                        return (
                                                            <div className="text-center" key={checkpoint.id}>
                                                                <span>∅</span>
                                                            </div>
                                                        )
                                                    }
                                                }
                                            })}

                                        </div>
                                    </div>
                                )
                            })}
                            <div className="text-center">
                                <span>{getTotalCheckpointPoints(enrollment.userId, challenge.id, enrollment.studentNumber)}</span>
                            </div>
                        </div>
                    )
                })}
            </>)
    }

    const renderCodeExerciseProgressPerStudent = (submissionsByUser: any, challengeId: string) => {
        let rowIndex = [0, 1, 2, 3, 4, 5, 6, 7, 8];
        console.log(submissionsByUser);
        console.log(submissionsByUser.length);
        return (
            <>
                {Object.keys(submissionsByUser).length > 0 && courseEnrollments.map((enrollment: CourseEnrollment, index: number) => {
                    return (
                        <div key={enrollment.id} className="grid grid-flow-col auto-cols-fr hover:bg-col-s2">
                            <div>{enrollment.studentNumber}</div>
                            {rowIndex.map((rowIndex: number) => {
                                return (
                                    <div className="text-center" key={rowIndex}>
                                        {submissionsByUser[enrollment.userId]?.[rowIndex]?.pointsAwarded}
                                    </div>
                                )
                            })}
                            <div>
                                {submissionsByUser[enrollment.userId]?.reduce((acc: number, submission: CodeExerciseSubmission) => acc + submission.pointsAwarded, 0)}
                            </div>
                        </div>
                    )
                })}
                {Object.keys(submissionsByUser).length === 0 && loadedChallengeIds.includes(challengeId) && <div>No submissions</div>}
                {Object.keys(submissionsByUser).length && !loadedChallengeIds.includes(challengeId) && <div>
                    <img className='animate-spin m-auto w-[32] h-[32]' src={require('assets/images/synapp.png')}></img>
                </div>}




                {/* {courseEnrollments.map((enrollment: CourseEnrollment, index: number) => {
                    return (
                        <div key={enrollment.id} className="grid grid-flow-col-dense hover:bg-col-s2">
                            <div>{enrollment.studentNumber}</div>
                            {challengeCodeExerciseSubmissions.filter((x) => x.ownerId === enrollment.userId).map((submission: CodeExerciseSubmission, index: number) => {
                                return (
                                    <div key={submission.id}>
                                            <div>{submission.pointsAwarded}</div>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })} */}
            </>)
    }

    const renderTotalCheckpointPoints = (type: string) => {
        class PointMapping {
            studentNumber: string = "";
            userId: string = "";
            totalCheckpoints: number = 0;
            totalPoints: number = 0;
        }

        let pointMappings = [] as PointMapping[];
        for (let enrollment of courseEnrollments) {
            let thisPointMapping = new PointMapping();
            thisPointMapping.studentNumber = enrollment.studentNumber;
            thisPointMapping.userId = enrollment.userId;

            for (let courseProgress of courseProgresses) {
                if (courseProgress.userId === enrollment.userId) {

                    for (let challengeProgress of courseProgress.challengeProgresses) {
                        if (challengeProgress.type === CourseChallengeType.TextChallenge) {
                            thisPointMapping.totalCheckpoints += challengeProgress.checkpointProgresses.length;
                            for (let checkpointProgress of challengeProgress.checkpointProgresses) {
                                if (checkpointProgress.pointsAwarded > 0) {
                                    thisPointMapping.totalPoints += checkpointProgress.pointsAwarded;
                                } else {
                                    thisPointMapping.totalPoints += 1;
                                }
                            }

                        }
                    }
                }
            }
            pointMappings.push(thisPointMapping);
        }

        //Copy pointMappings data to clipboard in a format that can be pasted into Excel
        let clipboardData = "";
        for (let pointMapping of pointMappings) {
            clipboardData += pointMapping.studentNumber + "\t" + pointMapping.totalCheckpoints + "\t" + pointMapping.totalPoints + "\n";
        }
        try {
            navigator.clipboard.writeText(clipboardData);
        } catch (error) {
            console.log("Failed to copy: ", error);
        }

        return (
            <>
                <div>{type}</div>
                <div className="grid grid-flow-col-dense">
                    <div className="font-bold">Student Number</div>
                    <div className="font-bold">Total Checkpoints</div>
                    <div className="font-bold">Total Points</div>
                </div>
                {pointMappings.map((pointMapping: PointMapping) => {
                    return (
                        <div key={pointMapping.userId} className="grid grid-flow-col-dense hover:bg-col-s2">
                            <div>{pointMapping.studentNumber}</div>
                            <div>{pointMapping.totalCheckpoints}</div>
                            <div>{pointMapping.totalPoints}</div>
                        </div>
                    )
                })}
            </>
        )

    }

    const renderTotalCodeExercisePoints = (type: string) => {
        class PointMapping {
            studentNumber: string = "";
            userId: string = "";
            totalCodeExercises: number = 0;
            totalPoints: number = 0;
        }

        console.log(allCodeExerciseSubmissions);

        let pointMappings = [] as PointMapping[];
        for (let enrollment of courseEnrollments) {
            let thisPointMapping = new PointMapping();
            thisPointMapping.studentNumber = enrollment.studentNumber;
            thisPointMapping.userId = enrollment.userId;

            for (let codeExerciseSubmission of allCodeExerciseSubmissions) {
                if (codeExerciseSubmission.ownerId === enrollment.userId) {
                    thisPointMapping.totalCodeExercises += 1;
                    thisPointMapping.totalPoints += codeExerciseSubmission.pointsAwarded;
                }
            }

            pointMappings.push(thisPointMapping);
        }

        //Copy pointMappings data to clipboard in a format that can be pasted into Excel
        let clipboardData = "";
        for (let pointMapping of pointMappings) {
            clipboardData += pointMapping.studentNumber + "\t" + pointMapping.totalCodeExercises + "\t" + pointMapping.totalPoints + "\n";
        }

        try {
            navigator.clipboard.writeText(clipboardData);
        } catch (error) {
            console.log("Failed to copy: ", error);
        }
         
        return (
            <>
                <div>{type}</div>
                <div className="grid grid-flow-col-dense">
                    <div className="font-bold">Student Number</div>
                    <div className="font-bold">Total Checkpoints</div>
                    <div className="font-bold">Total Points</div>
                </div>
                {pointMappings.map((pointMapping: PointMapping) => {
                    return (
                        <div key={pointMapping.userId} className="grid grid-flow-col-dense hover:bg-col-s2">
                            <div>{pointMapping.studentNumber}</div>
                            <div>{pointMapping.totalCodeExercises}</div>
                            <div>{pointMapping.totalPoints}</div>
                        </div>
                    )
                })}
            </>
        )

    }


    const renderChallenge = (challenge: CourseChallenge) => {
        if (challenge.type === CourseChallengeType.TextChallenge) {
            return (
                <div>
                    {/* //Headings */}
                    <div className="grid grid-flow-col-dense">
                        <div className="font-bold">Student Number</div>
                        {challenge.checkpoints.map((checkpoint: ChallengeCheckpoint) => {
                            return (
                                <div key={checkpoint.id}>
                                    <div className="font-bold">{checkpoint.type}</div>
                                </div>
                            )
                        })}
                        <div className="font-bold">Points</div>
                    </div>
                    {/* //Progress per student*/}
                    {renderCheckpointsProgressPerStudent(challenge)}
                    {/* <div className="grid grid-flow-col-dense">
                            {renderCheckpointsProgressPerStudent(challenge)}
                        </div> */}
                </div>
            )
        }
        else if (challenge.type === CourseChallengeType.CodeExerciseChallenge) {
            let challengeCodeExerciseSubmissions = codeExerciseSubmissions.filter((x) => x.challengeId === challenge.id);
            let submissionsByUser = groupBy(challengeCodeExerciseSubmissions, (x) => x.ownerId);
            //let highestNumberOfSubmissions = Math.max(...Object.values(submissionsByUser).map((x) => x.length));
            return (
                <div>
                    {/* //Headings */}
                    <div className="grid grid-flow-col auto-cols-fr">
                        <div className="font-bold">Student #</div>
                        <div className="font-bold text-center"># 1</div>
                        <div className="font-bold text-center"># 2</div>
                        <div className="font-bold text-center"># 3</div>
                        <div className="font-bold text-center"># 4</div>
                        <div className="font-bold text-center"># 5</div>
                        <div className="font-bold text-center"># 6</div>
                        <div className="font-bold text-center"># 7</div>
                        <div className="font-bold text-center"># 8</div>
                        <div className="font-bold text-center"># 9</div>
                        <div className="font-bold text-center">Tot. Points</div>
                    </div>
                    {/* //Progress per student*/}
                    {renderCodeExerciseProgressPerStudent(submissionsByUser, challenge.id)}
                </div>
            )
        }

    }

    const parseGradeCodeExercisesResponseModel = async (model: any) => {

        class GradeResponseReturnModel {
            codeExerciseSubmissions: CodeExerciseSubmission[] = [];
            gradeResponses: string[] = [];
            codeExercises: CodeExercise[] = [];
        }

        let gradeResponseReturnModel = model as GradeResponseReturnModel;
        let submissionsToUpdate = [];

        let indexCounter = 0;
        for (let gradeResponse of gradeResponseReturnModel.gradeResponses) {
            let thisSubmission = gradeResponseReturnModel.codeExerciseSubmissions[indexCounter];
            let grade = parseGradeCodeExerciseAILogic.parseNewAIResponse(gradeResponse);
            thisSubmission.grades.push(grade);
            //Calculate points awarded
            let pointsAwarded = grade.grade;
            let difficulty = gradeResponseReturnModel.codeExercises.find((x) => x.id === thisSubmission.codeExerciseId)?.difficulty;
            if (difficulty) {
                pointsAwarded = pointsAwarded * difficulty;
            }
            thisSubmission.pointsAwarded = pointsAwarded;
            submissionsToUpdate.push(thisSubmission);
            
            indexCounter++;
        }

        let response = await crudService.update(Class.codeExerciseSubmission, submissionsToUpdate);
        if (response.success) {
            console.log(response.payload);
        } else {
            errorService.handleError(response);
        }
        
    }

    const gradeCodeExercises = async () => {
        let response = await apiService.get(config.apiUrl + "/cms/gradeCodeExercises/" + course.id);
        if (response.success) {
            console.log(response.payload);
            parseGradeCodeExercisesResponseModel(response.payload);
        } else {
            errorService.handleError(response);
        }
    }

    return (
        <>
            {/* {JSON.stringify(course.courseChallenges)} */}
            {/* {createGUID(10)} */}
            <h1>Course Progress</h1>
            <Select value={selectedChallengeId} onChange={(e: any) => {
                setSelectedChallengeId(e.target.value);
                //If code exercise challenge not already loaded
                if (!loadedChallengeIds.includes(e.target.value)) {
                    //If code exercise challenge
                    if (course.courseChallenges.find((x) => x.id === e.target.value)?.type === CourseChallengeType.CodeExerciseChallenge) {
                        getCodeExerciseSubmissions(e.target.value);
                    }
                }
            }}>
                <option value="none">Select a challenge</option>
                {course && course.courseChallenges.map((challenge: CourseChallenge, index: number) => {
                    return (
                        <option key={challenge.id + index} value={challenge.id}>{challenge.name}</option>
                    )
                })}
            </Select>
            <div className="border border-2">
                {selectedChallengeId !== "none" && <div>{renderChallenge(course.courseChallenges.find((x) => x.id === selectedChallengeId) as CourseChallenge)}</div>}
            </div>
            <div>{courseProgresses.length}</div>
            <Button onClick={() => setShowPointTotalType("checkpoint")}>See Total Checkpoint Points</Button>
            <Button onClick={() => setShowPointTotalType("codeExercise")}>See Total CodeExercise Points</Button>
            <Button onClick={() => gradeCodeExercises()}>Grade Code Exercises</Button>
            {showPointTotalType==="checkpoint" && renderTotalCheckpointPoints(showPointTotalType)}
            {showPointTotalType==="codeExercise" && renderTotalCodeExercisePoints(showPointTotalType)}
        </>
    );
};

export default ViewCourseProgress;
